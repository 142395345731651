import React from "react";
import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import AuthorizationContainer from "./pages/authorization/authorization-container";
import MainContainer from "./pages/main/main-container";
import SpecialPagesContainer from "pages/special-pages/special-pages-container";
import HeaderContainer from "./header/header-container";
import ModalWindowContainer from "./modal-window/modal-window-container";
import {createBrowserHistory} from 'history';
import ShortAnalyticContainer from "./pages/short-analytic/short-analytic-container";
import SideBarContainer from "./items/side-bar/side-bar-container";
import TopicComparisonContainer from "pages/topic-comparison/topic-comparison-container";
import SettingsContainer from "./pages/settings-page/settings-container";
import AnalyticContainer from "pages/analytics/analytic-container";
import AdminContainer from "pages/admin/admin-container";
import AdminAccountsPageContainer from "pages/admin-accounts-page/admin-accounts-page-container";
import AdminTopicsContainer from "pages/admin/admin-topics/admin-topics-container";
import AdminMarkersContainer from "pages/admin/admin-markers/admin-markers-container";
import AdminMigrationContainer from "pages/admin/admin-migration/admin-migration-container";
import favicon from './assets/icons/favicon.ico';

const App = (props) => {

  let setColor = (theme) => {
    switch(theme.toLowerCase()){
      case 'light':
        document.documentElement.style.setProperty('--backgroundColor', 'var( --white )');
        document.documentElement.style.setProperty('--background', 'var( --backgroundLight )');
        document.documentElement.style.setProperty('--item', 'var( --purple )');
        document.documentElement.style.setProperty('--caption', 'var( --darkPurple )');
        document.documentElement.style.setProperty('--header', 'var( --backgroundLight )');
        document.documentElement.style.setProperty('--headerCaption', 'var( --darkPurple )');
        document.documentElement.style.setProperty('--controlButton', 'var( --backgroundLight )');
        document.documentElement.style.setProperty('--selectedButton', 'var( --purple )');
        document.documentElement.style.setProperty('--selectedButtonText', 'var( --white )');
        document.documentElement.style.setProperty('--selectedTableItem', 'var( --grey )');
        document.documentElement.style.setProperty('--postBackground', 'var( --backgroundLight )');
        document.documentElement.style.setProperty('--postTitleText', 'var( --blue )');
        document.documentElement.style.setProperty('--postText', 'var( --black )');
        document.documentElement.style.setProperty('--itemText', 'var( --purple )');
        document.documentElement.style.setProperty('--modalBackground', 'var( --backgroundLight )');
        document.documentElement.style.setProperty('--inputBackground', 'var( --white )');
        document.documentElement.style.setProperty('--scrollButton', 'var( --purple )');
        document.documentElement.style.setProperty('--scrollButtonHover', 'var( --darkPurple )');
        document.documentElement.style.setProperty('--panelList', 'var( --white )');
        document.documentElement.style.setProperty('--tableItem', 'var( --white )');
        document.documentElement.style.setProperty('--chartTableItem', 'var( --white )');
        document.documentElement.style.setProperty('--chartBackground', 'var( --backgroundLight )');
        document.documentElement.style.setProperty('--selectedModalItem', 'var( --grey )');
        document.documentElement.style.setProperty('--formBackground', 'var( --white )');
        document.documentElement.style.setProperty('--selectedDropdown', 'var( --purple )');
        document.documentElement.style.setProperty('--selectedDropdownText', 'var( --white )');
        document.documentElement.style.setProperty('--range', 'var( --purple )');
        break;
      case 'mix':
        document.documentElement.style.setProperty('--backgroundColor', 'var( --backgroundLight )');
        document.documentElement.style.setProperty('--background', 'var( --white )');
        document.documentElement.style.setProperty('--item', 'var( --darkPurple )');
        document.documentElement.style.setProperty('--caption', 'var( --darkPurple )');
        document.documentElement.style.setProperty('--header', 'var( --darkPurple )');
        document.documentElement.style.setProperty('--headerCaption', 'var( --white )');
        document.documentElement.style.setProperty('--controlButton', 'var( --white )');
        document.documentElement.style.setProperty('--selectedButton', 'var( --backgroundLight )');
        document.documentElement.style.setProperty('--selectedButtonText', 'var( --darkPurple )');
        document.documentElement.style.setProperty('--selectedTableItem', 'var( --grey )');
        document.documentElement.style.setProperty('--postBackground', 'var( --white )');
        document.documentElement.style.setProperty('--postTitleText', 'var( --blue )');
        document.documentElement.style.setProperty('--postText', 'var( --black )');
        document.documentElement.style.setProperty('--itemText', 'var( --darkPurple )');
        document.documentElement.style.setProperty('--modalBackground', 'var( --white )');
        document.documentElement.style.setProperty('--inputBackground', 'var( --lightGray )');
        document.documentElement.style.setProperty('--scrollButton', 'var( --darkPurple )');
        document.documentElement.style.setProperty('--scrollButtonHover', 'var( --purple )');
        document.documentElement.style.setProperty('--panelList', 'var( --white )');
        document.documentElement.style.setProperty('--tableItem', 'var( --backgroundLight )');
        document.documentElement.style.setProperty('--chartTableItem', 'var( --backgroundLight )');
        document.documentElement.style.setProperty('--chartBackground', 'var( --white )');
        document.documentElement.style.setProperty('--selectedModalItem', 'var( --grey )');
        document.documentElement.style.setProperty('--formBackground', 'var( --white )');
        document.documentElement.style.setProperty('--selectedDropdown', 'var( --darkPurple )');
        document.documentElement.style.setProperty('--selectedDropdownText', 'var( --white )');
        document.documentElement.style.setProperty('--range', 'var( --purple )');
        break;
      case 'dark':
        document.documentElement.style.setProperty('--backgroundColor', 'var( --mediumPurple )');
        document.documentElement.style.setProperty('--background', 'var( --purple )');
        document.documentElement.style.setProperty('--item', 'var( --darkPurple )');
        document.documentElement.style.setProperty('--caption', 'var( --white )');
        document.documentElement.style.setProperty('--header', 'var( --darkPurple )');
        document.documentElement.style.setProperty('--headerCaption', 'var( --white )');
        document.documentElement.style.setProperty('--controlButton', 'var( --mediumPurple )');
        document.documentElement.style.setProperty('--selectedButton', 'var( --backgroundLight )');
        document.documentElement.style.setProperty('--selectedButtonText', 'var( --darkPurple )');
        document.documentElement.style.setProperty('--selectedTableItem', 'var( --darkPurple )');
        document.documentElement.style.setProperty('--postBackground', 'var( --darkPurple )');
        document.documentElement.style.setProperty('--postTitleText', 'var( --blue )');
        document.documentElement.style.setProperty('--postText', 'var( --grey )');
        document.documentElement.style.setProperty('--itemText', 'var( --white )');
        document.documentElement.style.setProperty('--modalBackground', 'var( --mediumPurple )');
        document.documentElement.style.setProperty('--inputBackground', 'var( --grey )');
        document.documentElement.style.setProperty('--scrollButton', 'var( --purple )');
        document.documentElement.style.setProperty('--scrollButtonHover', 'var( --lightPurple )');
        document.documentElement.style.setProperty('--panelList', 'var( --mediumPurple )');
        document.documentElement.style.setProperty('--tableItem', 'var( --lightPurple )');
        document.documentElement.style.setProperty('--chartTableItem', 'var( --darkPurple )');
        document.documentElement.style.setProperty('--chartBackground', 'var( --darkPurple )');
        document.documentElement.style.setProperty('--selectedModalItem', 'var( --mediumPurple )');
        document.documentElement.style.setProperty('--formBackground', 'var( --lightPurple )');
        document.documentElement.style.setProperty('--selectedDropdown', 'var( --white )');
        document.documentElement.style.setProperty('--selectedDropdownText', 'var( --darkPurple )');
        document.documentElement.style.setProperty('--range', 'var( --purple )');
        break;
      case 'echo':
        document.documentElement.style.setProperty('--backgroundColor', 'var( --white )');
        document.documentElement.style.setProperty('--background', 'var( --echoBackground )');
        document.documentElement.style.setProperty('--item', 'var( --echoSecondary )');
        document.documentElement.style.setProperty('--caption', 'var( --echoTextPrimary )');
        document.documentElement.style.setProperty('--header', 'var( --echoHeader )');
        document.documentElement.style.setProperty('--headerCaption', 'var( --echoWhite )');
        document.documentElement.style.setProperty('--controlButton', 'var( --echoBackground )');
        document.documentElement.style.setProperty('--selectedButton', 'var( --echoSecondary )');
        document.documentElement.style.setProperty('--selectedButtonText', 'var( --white )');
        document.documentElement.style.setProperty('--selectedTableItem', 'var( --grey )');
        document.documentElement.style.setProperty('--postBackground', 'var( --echoBackground )');
        document.documentElement.style.setProperty('--postTitleText', 'var( --echoSecondary )');
        document.documentElement.style.setProperty('--postText', 'var( --black )');
        document.documentElement.style.setProperty('--itemText', 'var( --echoSecondary )');
        document.documentElement.style.setProperty('--modalBackground', 'var( --echoBackground )');
        document.documentElement.style.setProperty('--inputBackground', 'var( --white )');
        document.documentElement.style.setProperty('--scrollButton', 'var( --echoSecondary )');
        document.documentElement.style.setProperty('--scrollButtonHover', 'var( --echoHeader )');
        document.documentElement.style.setProperty('--panelList', 'var( --white )');
        document.documentElement.style.setProperty('--tableItem', 'var( --white )');
        document.documentElement.style.setProperty('--chartTableItem', 'var( --white )');
        document.documentElement.style.setProperty('--chartBackground', 'var( --echoBackground )');
        document.documentElement.style.setProperty('--selectedModalItem', 'var( --grey )');
        document.documentElement.style.setProperty('--formBackground', 'var( --white )');
        document.documentElement.style.setProperty('--selectedDropdown', 'var( --echoSecondary )');
        document.documentElement.style.setProperty('--selectedDropdownText', 'var( --white )');
        document.documentElement.style.setProperty('--range', 'var( --echoPlaceholder )');
    }
  }
  let old = document.getElementById('media-monitoring-favicon');
  if(!old){
    let link = document.createElement('link');

    link.rel = 'icon';
    link.id = 'media-monitoring-favicon'
    link.type = 'type="image/x-icon';
    link.href = favicon//'./assets/icons/favicon.ico';
    document.head.appendChild(link);
  }

  let state = props.store;
  let pageContainerStyle = 'pageContainer';
  setColor(state.mainThemes.selectedTheme)

  if(state.header.isShowPanel) pageContainerStyle += ' withPanel';
  if(state.SideBarMenu.showLeftMenu && state.header.deviceType !== 'mobile' && state.header.deviceType !== 'tablet') pageContainerStyle += ' LeftMenu';
  if(state.SideBarMenu.showLeftMenu && state.header.deviceType === 'mobile' && state.header.deviceType !== 'tablet') pageContainerStyle += ' LeftMenuMobile';
  if(state.SideBarMenu.showRightMenu && state.header.deviceType === 'mobile') pageContainerStyle += ' RightMenuMobile';
  if(state.SideBarMenu.showRightMenu && state.header.deviceType !== 'mobile') pageContainerStyle += ' RightMenu';


  let appClass = "App "+state.mainThemes.selectedTheme;
  let role = state.Account.role;

  const browserHistory = createBrowserHistory();

  const userRoutes = [
                          <Route key={1} path="/" element={<AuthorizationContainer />} />,
                          <Route key={2} path="/page" element={<AuthorizationContainer />} />,
                          <Route key={3} path="/page/short-analytic" element={<ShortAnalyticContainer />} />,
                          <Route key={4} path="/page/main" element={<MainContainer type={'Documents'}/>} />,
                          <Route key={5} path="/page/topic-comparison" element={<TopicComparisonContainer type={'Comparision'}/>} />,
                          <Route key={6} path="/page/settings" element={<SettingsContainer />} />,
                          /*<Route key={7} path="/page/extended-analytic" element={ <ExtendedAnalyticContainer type={'ExtendedAnalytic'}/> } />,*/
                          <Route key={7} path="/page/analytic" element={ <AnalyticContainer type={'Analytic'}/> } />,
                          <Route key={8} path="/page/favourite-authors-resources" element={<SpecialPagesContainer type={'Favourite'} />} />,
                          <Route key={9} path="/page/favourite-publications" element={<SpecialPagesContainer type={'FavouritePublications'} />} />,
                          <Route key={10} path="/page/black-list" element={<SpecialPagesContainer type={'BlackList'} />} />,
                          <Route key={11} path="/page/recycle-bin" element={<SpecialPagesContainer type={'RecycleBin'}/>} />,
  ]

  const adminRoutes = userRoutes;
  //adminRoutes.unshift(<Route key={0} path="/page/admin" element={<AdminContainer />} />)
  
  let superAdminRoutes = [
                            <Route key={0} path="/" element={<AuthorizationContainer />} />,
                            <Route key={1} path="/page" element={<AuthorizationContainer />} />,
                            <Route key={2} path="/page/admin" element={<AdminContainer />} />,
                            <Route key={2} path="/page/admin-accounts" element={<AdminAccountsPageContainer />} />,
                            <Route key={2} path="/page/admin-topics" element={<AdminTopicsContainer />} />,
                            <Route key={22} path="/page/admin-markers" element={<AdminMarkersContainer />} />,
                            <Route key={23} path="/page/admin-migration" element={<AdminMigrationContainer />} />
  ]

  if(state.Account.role === 'ROLE_SUPER_ADMIN' && state.Account.companyId){
    superAdminRoutes = [];
    superAdminRoutes = userRoutes//superAdminRoutes.concat(userRoutes)
  }
                        
  const markerRoutes = [
    <Route key={0} path="/" element={<AuthorizationContainer />} />,
    <Route key={1} path="/page" element={<AuthorizationContainer />} />,
    <Route key={2} path="/page/main" element={<MainContainer type={'Documents'}/>} />,
    <Route key={6} path="/page/black-list" element={<SpecialPagesContainer type={'BlackList'} />} />,
    <Route key={7} path="/page/recycle-bin" element={<SpecialPagesContainer type={'RecycleBin'}/>} />,
  ]

  const routes = () => {

    switch(role){
      
      case 'ROLE_SUPER_ADMIN':
        return superAdminRoutes;
      case 'ROLE_MARKER':
        return markerRoutes;
      case 'ROLE_USER':
      case 'ROLE_ADMIN':
        return userRoutes
      default:
        return userRoutes
    }
  }

  return (
    <div className={appClass}>
        <Router history={browserHistory}>
          <HeaderContainer/>
          <div className={pageContainerStyle}>
            <SideBarContainer />
              <Routes>
                {routes()}
              </Routes>
              
          </div>
          <ModalWindowContainer state={ props.Modal } />
          
        </Router>
    </div>
  )
    
  }
  
  export default App;